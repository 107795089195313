import React, { useState } from "react"
import {
  Box,
  Menu,
  Image,
  Select,
  Button,
  Text,
  Circle,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Input,
  Checkbox,
  Center,
  FormControl,
} from "@chakra-ui/react"
import IconPlus from "../Icons/IconPlus"
import {
  AutoComplete,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteInput,
} from "@choc-ui/chakra-autocomplete"

const MarkerModal = props => {
  // * Temp Markerobject von props oder neu
  const [editTempObjectUP, setEditTempObjectUP] = useState(props.markerObject)
  const [inputValidated, setInputValidated] = useState(false)
  const [visibility, setVisibility] = useState("top")

  // * Scene selection strings
  const scene_top = "Szene 1 und Szene 2"
  const scene_bottom = "Nur Szene 2"

  // * Editor
  const EditIcon = () => {
    // * States
    const [editTempObject, setEditTempObject] = useState(editTempObjectUP)

    // * Validate function -> temp object aus editor nach oben holen
    function validateInput() {
      setEditTempObjectUP({
        ...editTempObject,
        type: "static",
      })
      setInputValidated(true)
    }

    // * ICON
    // * Change handlers
    let MhandleInputChangeIcon = e => {
      let inputValue = e.target.value
      // console.log("ICON: ", inputValue)
      if (props.staticIconData.find(i => i.name === inputValue) !== undefined) {
        setEditTempObject({
          ...editTempObject,
          visibility: "top",
          title: inputValue,
          canExpand: false,
          icon: props.staticIconData.find(i => i.name === inputValue).svg,
          type: "static",
        })
      } else {
        setEditTempObject({
          ...editTempObject,
          visibility: "top",
          canExpand: false,
          icon: "",
          type: "static",
        })
      }
    }

    // console.log("ICON DATA", props.staticIconData)

    // * Get Icon Options
    const iMI = props.staticIconData.map((item, index) => (
      <option key={item.name} value={item.name}>
        {item.name}
      </option>
    ))

    return (
      <Box>
        {editTempObject.icon !== "" ? (
          <Center mt="2" mb="2">
            <Circle w="64px" h="64px" bg="white">
              <Image src={editTempObject.icon} />
            </Circle>
          </Center>
        ) : (
          "Kein Icon ausgewählt."
        )}
        <Menu>
          <Select
            onChange={MhandleInputChangeIcon}
            bg="white"
            placeholder="Icon auswählen"
          >
            {iMI}
          </Select>
        </Menu>
        <Text mt="4" fontStyle="italic">
          Zwischenspeichern um das statische Objekt der Karte hinzufügen zu
          können.
        </Text>
        <Center mt="2">
          <Button
            colorScheme="blue"
            disabled={inputValidated}
            onClick={validateInput}
          >
            Zwischenspeichern
          </Button>
        </Center>
      </Box>
    )
  }

  // * INTERACTIVE
  // * Editor
  const EditInteractive = () => {
    // * States
    const [editTempObject, setEditTempObject] = useState(editTempObjectUP)
    const [canExpand, setCanExpand] = useState(editTempObjectUP.canExpand)
    const [tempVisibility, setTempVisibility] = useState("top")
    const [staticIconData, setStaticIconData] = useState(props.staticIconData)
    const [staticImageData, setStaticImageData] = useState(
      props.staticImageData
    )

    // * Validate function -> temp object aus editor nach oben holen
    function validateInput() {
      setVisibility(tempVisibility)
      setEditTempObjectUP({
        ...editTempObject,
        visibility: tempVisibility,
        type: "interactive",
        canExpand: canExpand,
      })
      setInputValidated(true)
    }

    // * Change handlers
    // let MhandleInputChangeImage = e => {
    //   let inputValue = e.target.value
    //   if (staticImageData.find(i => i.name === inputValue) !== undefined) {
    //     setEditTempObject({
    //       ...editTempObject,
    //       image: staticImageData.find(i => i.name === inputValue).url,
    //     })
    //   } else {
    //     setEditTempObject({
    //       ...editTempObject,
    //       image: "",
    //     })
    //   }
    // }

    let MhandleInputChangeImageName = name => {
      let inputValue = name
      if (staticImageData.find(i => i.name === inputValue) !== undefined) {
        setEditTempObject({
          ...editTempObject,
          image: staticImageData.find(i => i.name === inputValue).url,
        })
      } else {
        setEditTempObject({
          ...editTempObject,
          image: "",
        })
      }
    }

    let handleScenePart = e => {
      let inputValue = e.target.value
      if (inputValue === scene_top) {
        setEditTempObject({
          ...editTempObject,
          visibility: "top",
        })
        setTempVisibility("top")
      } else {
        setEditTempObject({
          ...editTempObject,
          visibility: "bottom",
        })
        setTempVisibility("bottom")
      }
    }

    let handleInputProjektziel = e => {
      let inputValue = e.target.value
      if (
        props.staticProjektzielData.find(i => i.title === inputValue) !==
        undefined
      ) {
        const projektziel = props.staticProjektzielData.find(
          i => i.title === inputValue
        )
        // console.log("projektziel", projektziel)
        // * Set Title, Image and Link
        setEditTempObject({
          ...editTempObject,
          title: projektziel.projectTitle,
          link: {
            title: "Mehr zu diesem Projekt",
            url: projektziel.url,
          },
          image:
            staticImageData.find(i => i.name === projektziel.image) !==
            undefined
              ? staticImageData.find(i => i.name === projektziel.image).url
              : "",
          expandedContent: {
            ...editTempObject.expandedContent,
            title: projektziel.title,
            link: {
              title: "Mehr zu diesem Projekt",
              url: projektziel.url,
            },
          },
        })
      }
    }

    let handleInputChangeTitle = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        title: inputValue,
      })
    }

    let handleInputChangeLinkTitle = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        link: {
          title: inputValue,
          url: editTempObject.link.url,
        },
      })
    }
    let handleInputChangeLinkUrl = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        link: {
          title: editTempObject.link.title,
          url: inputValue,
        },
      })
    }
    let handleInputChangeExpandableTitle = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        expandedContent: {
          title: inputValue,
          content: editTempObject.expandedContent.content,
          link: editTempObject.expandedContent.link,
        },
      })
    }
    let handleInputChangeExpandableContent = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        expandedContent: {
          title: editTempObject.expandedContent.title,
          content: inputValue,
          link: editTempObject.expandedContent.link,
        },
      })
    }
    let handleInputChangeExpandableLinkTitle = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        expandedContent: {
          title: editTempObject.expandedContent.title,
          content: editTempObject.expandedContent.content,
          link: {
            title: inputValue,
            url: editTempObject.link.url,
          },
        },
      })
    }
    let handleInputChangeExpandableLinkUrl = e => {
      let inputValue = e.target.value
      setEditTempObject({
        ...editTempObject,
        expandedContent: {
          title: editTempObject.expandedContent.title,
          content: editTempObject.expandedContent.content,
          link: {
            title: editTempObject.expandedContent.link.title,
            url: inputValue,
          },
        },
      })
    }

    // * ICON
    // * Change handlers
    let MhandleInputChangeIcon = e => {
      let inputValue = e.target.value
      // console.log("ICON: ", inputValue)
      if (staticIconData.find(i => i.name === inputValue) !== undefined) {
        setEditTempObject({
          ...editTempObject,
          icon: staticIconData.find(i => i.name === inputValue).svg,
        })
      } else {
        setEditTempObject({
          ...editTempObject,
          icon: "",
        })
      }
    }

    // console.log("ICON DATA", staticIconData)
    // console.log("Can expand?", canExpand)

    // * Get Icon Options
    const iMI = staticIconData.map((item, index) => (
      <option key={`iconKey_${index}_${item.name}`} value={item.name}>
        {item.name}
      </option>
    ))

    // * Get Image Options
    // const imageOptions = staticImageData.map((item, index) => (
    //   <option key={`imagekey_${index}_${item.name}`} value={item.name}>
    //     {item.name}
    //   </option>
    // ))

    // * Get Projektziel Options
    const projektzielOptions = props.staticProjektzielData.map(
      (item, index) => (
        <option
          key={`projektzielkey_${index}_${item.title}`}
          value={item.title}
        >
          {item.title}
        </option>
      )
    )

    return (
      <>
        <Box>
          <Text fontWeight="bold" mt="2" mb="2">
            Icon{" "}
            <span style={{ fontWeight: "normal", fontSize: "0.8em" }}>
              z.B. für themenverwandtes Projekt, Projekt am gleichen Ort oder
              Repräsentant wählen
            </span>
          </Text>
          {editTempObject.icon !== "" ? (
            <Center mt="2" mb="2">
              <Circle w="64px" h="64px" bg="white">
                <Image m="12px" src={editTempObject.icon} />
              </Circle>
            </Center>
          ) : (
            "Kein Icon ausgewählt."
          )}
          <Menu>
            <Select
              onChange={MhandleInputChangeIcon}
              bg="white"
              placeholder="Icon auswählen"
            >
              {iMI}
            </Select>
          </Menu>
          <Text fontWeight="bold" mt="2">
            Welcher Ausschnitt soll bearbeitet werden?
          </Text>
          <Menu>
            <Select bg="white" onChange={handleScenePart}>
              <option>{scene_top}</option>
              <option>{scene_bottom}</option>
            </Select>
          </Menu>
          <Text fontWeight="bold" mt="2">
            (Optional) Soll ein Projekt verlinkt werden?
          </Text>
          <Menu>
            <Select bg="white" onChange={handleInputProjektziel}>
              <option>Projektdaten aus einer Vorauswahl übernehmen</option>
              {projektzielOptions}
            </Select>
          </Menu>
          <Text fontWeight="bold" mt="4" mb="2">
            Überschrift kleines Overlay
          </Text>
          <Textarea
            key="interactive_input_title"
            bg="white"
            value={editTempObject.title}
            onChange={handleInputChangeTitle}
            placeholder="Texteingabe (max. 30 Zeichen)"
          />
          <Text mt="2" fontWeight="bold" mb="2">
            Bild kleines Overlay
          </Text>
          {editTempObject.image !== "" ? (
            <Box overflow="hidden" h="200px" maxW="200px">
              <Image src={editTempObject.image} />
            </Box>
          ) : (
            "Kein Bild ausgewählt."
          )}
          {/* <Menu>
            <Select
              onChange={MhandleInputChangeImage}
              bg="white"
              placeholder="Bild auswählen"
              defaultValue={editTempObject.image}
            >
              {imageOptions}
            </Select>
          </Menu> */}
          <FormControl>
            <AutoComplete
              emptyState=" (Kein Bild gefunden) Text eingeben um nach Bild zu suchen..."
              openOnFocus
              closeOnSelect
            >
              <AutoCompleteInput bg="white" placeholder="Nach Bild suchen..." />
              <AutoCompleteList bg="white">
                {staticImageData.map(image => (
                  <AutoCompleteItem
                    onClick={() => MhandleInputChangeImageName(image.name)}
                    _hover={{ background: "gray.200" }}
                    key={image.name}
                    value={image.name}
                  >
                    {image.name}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
          </FormControl>
          <Text mt="4">
            <b>Beschreibender Text oder Text mit URL</b>
          </Text>
          <InputGroup mt="2" bg="white" size="md">
            <InputLeftAddon bg="gray.200" fontWeight="bold" children="Text" />
            <Input
              value={editTempObject.link.title}
              onChange={handleInputChangeLinkTitle}
              placeholder="Sichtbarer Text (z.B. Mehr erfahren)"
            />
          </InputGroup>
          <InputGroup mt="2" bg="white" size="md">
            <InputLeftAddon bg="gray.200" fontWeight="bold" children="URL" />
            <Input
              value={editTempObject.link.url}
              onChange={handleInputChangeLinkUrl}
              placeholder="http://.... oder leer lassen wenn kein Link gesetzt wird"
            />
          </InputGroup>
        </Box>
        <Box
          mt="4"
          pt="4"
          borderTop="1px solid white"
          borderBottom="1px solid white"
          pb="4"
        >
          <Checkbox
            isChecked={canExpand}
            onChange={e => setCanExpand(e.target.checked)}
            fontWeight="bold"
            colorScheme="blue"
            size="lg"
            bg="white"
            border="1px solid"
            borderColor="gray.200"
            p="2"
            rounded="md"
          >
            <Text fontSize="1.2em">{"Erweitertes Overlay"}</Text>
          </Checkbox>
          {canExpand ? (
            <Box>
              <Text fontWeight="bold" mt="4" mb="2">
                Überschrift erweitertes Overlay
              </Text>
              <Textarea
                bg="white"
                value={editTempObject.expandedContent.title}
                onChange={handleInputChangeExpandableTitle}
                placeholder="Texteingabe (max. 30 Zeichen)"
              />
              <Text fontWeight="bold" mt="4" mb="2">
                Inhalt erweitertes Overlay
              </Text>
              <Textarea
                bg="white"
                value={editTempObject.expandedContent.content}
                onChange={handleInputChangeExpandableContent}
                placeholder="Beschreibung (max. 100 Zeichen)"
              />
              <Text mt="4">
                <b>Link</b>
              </Text>
              <InputGroup mt="2" bg="white" size="md">
                <InputLeftAddon
                  bg="gray.200"
                  fontWeight="bold"
                  children="Title"
                />
                <Input
                  value={editTempObject.expandedContent.link.title}
                  onChange={handleInputChangeExpandableLinkTitle}
                  placeholder="Projektseite XY..."
                />
              </InputGroup>
              <InputGroup mt="2" bg="white" size="md">
                <InputLeftAddon
                  bg="gray.200"
                  fontWeight="bold"
                  children="URL"
                />
                <Input
                  value={editTempObject.expandedContent.link.url}
                  onChange={handleInputChangeExpandableLinkUrl}
                  placeholder="https://..."
                />
              </InputGroup>
            </Box>
          ) : (
            <></>
          )}
        </Box>
        <Center mt="4">
          <Button colorScheme="blue" onClick={validateInput}>
            Eingabe überprüfen
          </Button>
        </Center>
      </>
    )
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  function handleModalOpen() {
    setEditTempObjectUP(props.markerObject)
    setInputValidated(false)
    setVisibility("top")
    onOpen()
  }

  return (
    <>
      <Button w="100%" onClick={handleModalOpen}>
        {props.openButton === undefined ? (
          props.type === "static" ? (
            <>
              <Text mr="1">Statisches Objekt hinzufügen</Text> <IconPlus />
            </>
          ) : (
            <>
              <Text mr="1">Interaktives Objekt hinzufügen</Text> <IconPlus />
            </>
          )
        ) : (
          props.openButton
        )}
      </Button>

      <Modal key="modal" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minW="50vw">
          <ModalHeader>
            <Text fontSize="1.2em">
              {props.type === "static"
                ? "Statisches Objekt hinzufügen"
                : "Interaktives Objekt hinzufügen"}
            </Text>
            <Text fontSize="1em">
              {props.type === "static"
                ? "Ein Icon für ein statisches Objekt wählen oder austauschen."
                : "(z.B. Projekte, Repräsentanten)"}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderRadius="lg" mt="2" p="2" bg="gray.100">
              {props.type === "static" ? (
                <EditIcon key="editIcon" />
              ) : (
                <EditInteractive key="editInteractive" />
              )}
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Verwerfen
            </Button>
            <Button
              disabled={!inputValidated}
              colorScheme="blue"
              onClick={() => {
                props.addMarkerFunction(editTempObjectUP)
                // console.log("New Marker: ", editTempObjectUP)
                onClose()
              }}
            >
              Objekt platzieren
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default MarkerModal
