import React from "react"
import {
  Circle,
  Text,
  Flex,
  Heading,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react"

const PopoverInteractiveStatic = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <Flex cursor="pointer">
          <Text h="32px" lineHeight="32px">
            Erklärung
          </Text>
          <Circle
            ml="2"
            fontWeight="bold"
            cursor="pointer"
            bg="gray.300"
            w="32px"
            h="32px"
          >
            ?
          </Circle>
        </Flex>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Erklärung</PopoverHeader>
        <PopoverBody shadow="lg">
          <Heading fontSize="lg" mb="2">
            Statisch:
          </Heading>
          <Text>
            Statische Objekte beschreiben{" "}
            <b>mithilfe von Icons inhaltliche Erweiterungen der 3D Karte</b>.
            Als eine solche grafische Informationsschicht sind sie farblich an
            die 3D Karte angepasst und sind <b>weder klickbar</b> noch{" "}
            <b>verlinkbar</b>.
          </Text>
          <Divider mt="2" mb="2" />
          <Heading fontSize="lg" mb="2">
            Interaktiv:
          </Heading>
          <Text>
            Interaktive Objekte ermöglichen es{" "}
            <b>Inhalte des Guides zu verlinken</b>. Als Interaktionselemente
            bieten sie Möglichkeit <b>klickbare Icons</b>, kleines Overlays und
            ein erweitertes zweites Overlay zu platzieren.
          </Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default PopoverInteractiveStatic
